import './sideMenu.style.scss';
import { useContext } from 'react';
import AppContext from 'src/core/context/global/AppContext';
import { useTranslation } from 'react-i18next';
import { can, getUserRole, hideFeature } from 'src/core/utils/global.utils';
import { isSinghealthInstance } from 'src/core/utils/instance.utils';
import healbotAvatar from '../../../assets/healBot/healbot_avatar.png';
import healbot from '../../../assets/healBot/healbot.png';
import { Typography } from '@mui/material';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AddIcCallOutlinedIcon from '@mui/icons-material/AddIcCallOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { Structure } from 'src/core/models/session.models';

interface Props {
  openPage: any;
  signOut: any;
  getItemClassName: any;
  openTelehealth: any;
  openAbout: any;
}

function AccountMenu(props: Props) {
  const { openPage, signOut, getItemClassName, openTelehealth, openAbout } = props;
  const { t } = useTranslation();
  const { user, setStructure, abilities, structure } = useContext(AppContext);

  const backToAccounts = () => {
    if (structure.parent) {
      setStructure(structure.parent);
      openPage('/accounts');
    }
  };

  return (
    <div className='side-menu'>
      <ul className='items'>
        {user.structures && can(getUserRole(user, structure), ['view_accounts'], abilities) && (
          <li className={getItemClassName('/accounts')} onClick={backToAccounts}>
            <ArrowBackOutlinedIcon className='item-icon' />
            <Typography noWrap>
              {user.structures?.[0]?.type === 'group' ? t('menu.groupAdministration') : t('menu.accountAdministration')}
            </Typography>
          </li>
        )}
        {hideFeature && (
          <li className={getItemClassName('/dashboard')} onClick={() => openPage('/dashboard')}>
            <BarChartOutlinedIcon className='item-icon' />
            <Typography noWrap>{t('menu.dashboard')}</Typography>
          </li>
        )}
        <li className={getItemClassName('/sites')} onClick={() => openPage('/sites')}>
          <HomeOutlinedIcon className='item-icon' />
          <Typography noWrap>{t('menu.sites')}</Typography>
        </li>
        <li className={getItemClassName('/users')} onClick={() => openPage('/users')}>
          <PeopleAltOutlinedIcon className='item-icon' />
          <Typography noWrap>{t('menu.userManagement')}</Typography>
        </li>

        {!isSinghealthInstance() && can(structure, ['export_csv'], abilities) && (
          <li className={getItemClassName('/reports')} onClick={() => openPage('/reports')}>
            <AssignmentOutlinedIcon className='item-icon' />
            <Typography noWrap>{t('menu.report')}</Typography>
          </li>
        )}
        {can(structure, ['export_csv'], abilities) && (
          <li className={getItemClassName('/LogsReport')} onClick={() => openPage('/LogsReport')}>
            <AssignmentOutlinedIcon className='item-icon' />
            <Typography noWrap>{t('menu.systemlogs')}</Typography>
          </li>
        )}
        {hideFeature && (
          <li className={'item'} onClick={openTelehealth}>
            <AddIcCallOutlinedIcon className='item-icon' />
            <Typography noWrap>{t('menu.telehealth')}</Typography>
          </li>
        )}
        {structure.healbot_enabled && can(structure, ['healbot'], abilities) && (
          <li className={getItemClassName('/healBot')} onClick={() => openPage('/healBot')}>
            <img src={healbotAvatar} alt='healbotAvatar' className='healbotAvatar-icon' />
            <img src={healbot} alt='healbot' className='healbot-text' />
          </li>
        )}
        <li className={getItemClassName('/settings')} onClick={() => openPage('/settings')}>
          <SettingsOutlinedIcon className='item-icon' />
          <Typography noWrap>{t('menu.settings')}</Typography>
        </li>
        {!isSinghealthInstance() && (
          <li className={'item'}>
            <a href={'https://ekareinchelp.zendesk.com'} target='_blank' rel='noopener noreferrer'>
              <HelpOutlineOutlinedIcon className='item-icon' />
              <Typography noWrap>{t('menu.help')}</Typography>
            </a>
          </li>
        )}
        <li className={'item'} onClick={openAbout}>
          <InfoOutlinedIcon className='item-icon' />
          <Typography noWrap>{t('menu.about')}</Typography>
        </li>
        {!isSinghealthInstance() && (
          <li className={getItemClassName('/admin')} onClick={() => openPage('/admin')}>
            <MenuOutlinedIcon className='item-icon' />
            {t('Administration')}
          </li>
        )}
        <div className='bottom-items'>
          <li key={'signOut'} className='item' onClick={signOut}>
            <LogoutOutlinedIcon className='item-icon' />
            <Typography noWrap>{t('menu.signOut')}</Typography>
          </li>
        </div>
      </ul>
    </div>
  );
}

export default AccountMenu;
